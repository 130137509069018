import axios from "axios";
import { CONFIG } from "../config";

export const api = axios.create({
    baseURL: CONFIG.API_URL,
});

export const apiEcvaring = axios.create({
    baseURL: CONFIG.ECVARING_API_URL,
    timeout: 18000,
});