import { FC, useEffect, useState } from "react";
import css from "./DetailScreen.module.css";
import cn from "classnames";

import TradeCard from "../../../../components/TradeCard/TradeCard";
import BuyBtn from "../../../../components/UI/Buttons/BuyBtn/BuyBtn";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import { getCookie } from "../../../../helpers/cockieHelper";
import { api, apiEcvaring } from "../../../../api/api";
import { IBuyBundleBody } from "../../Memtrade";
import { ModalProps } from "../../../interfaces";
import Modal from "../../../../components/UI/Modals/BuyPremiumModal/Modal";

import axios from "axios";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/UI/Buttons/Button";
import { postEvent } from "@telegram-apps/sdk";

interface TradeCardProps {
    id: number;
    name: string;
    color: string;
    bothCount: number;
    maxCount: number;
    setMemeScreen: React.Dispatch<React.SetStateAction<string>>;
    buyBundleBody: IBuyBundleBody;
    getUserData: () => Promise<void>;
    procent: string;
    totalEarning: number;
    deposit: number;
    price: number;
}

const DetailScreen: FC<TradeCardProps> = ({
    id,
    name,
    color,
    bothCount,
    maxCount,
    setMemeScreen,
    buyBundleBody,
    getUserData,
    procent,
    totalEarning,
    deposit,
    price,
}) => {
    const [paymentId, setPaymentId] = useState<string>();
    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [modalState, setModalState] = useState<ModalProps>({} as ModalProps);
    const { t } = useTranslation();
    const mockDescription = t("mockDescription");

    const makeInvest = async () => {
        try {
            const response = await api.post(`/payments/invest_bundle/`, buyBundleBody, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${getCookie("key")?.toString()}`,
                    "Content-Type": "application/json",
                },
            });
    
            setPaymentId(response.data.paymentId);
            setModalState((prevState) => ({
                ...prevState,
                type: "pending",
            }));
            setOpenModal(true);
    
            const language = localStorage.getItem("language") || "ru";
    
            const paymentUrl = new URL(response.data.paymentUrl);
            paymentUrl.searchParams.set("lng", language); 
    
            const linkParams = {
                url: paymentUrl.toString(),
                try_instant_view: false,
            };
    
            postEvent("web_app_open_link", linkParams);
        } catch (error) {
            console.error("Error fetching cards:", error);
        }
    };
    

    useEffect(() => {
        console.log(buyBundleBody);
    }, []);

    useEffect(() => {
        if (!paymentId) return;
        const intervalId = setInterval(async () => {
            try {
                const response = await apiEcvaring.get(
                    `/payment/status?paymentId=${paymentId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                switch (response.data.status) {
                    // case "pending":
                    //   clearInterval(intervalId);
                    //   setModalState((prevState) => ({
                    //     ...prevState,
                    //     type: "falled",
                    //     onClick: makeInvest,
                    //   }));
                    //   break;
                    case "paid":
                        clearInterval(intervalId);

                        setModalState((prevState) => ({
                            ...prevState,
                            type: "success",
                            onClick: () => setMemeScreen("memetrade"),
                        }));
                        getUserData();
                        break;
                    case "failed":
                        clearInterval(intervalId);
                        setModalState((prevState) => ({
                            ...prevState,
                            type: "falled",
                            onClick: makeInvest,
                        }));
                        break;
                }
            } catch (error) {
                console.error("error while init invest", error);
            }
        }, 4000);
        return () => clearInterval(intervalId);
    }, [paymentId]);

    const expendBundle = () => {
        setOpenModal(true);

        setModalState((prevState) => ({
            ...prevState,
            type: "premium",
            onClick: makeInvest,
        }));
    };

    console.log(color, name);

    return (
        <>
            <div
                className={cn(css.wrapper, {
                    [css.wrapperBgBlue]: color === "blue",
                    [css.wrapperBgRed]: color === "red",
                    [css.wrapperBgPurpule]: color === "purple",
                    [css.wrapperBgGreen]: color === "green",
                    [css.wrapperBgDacrkGreen]: color === "darck-green",
                    [css.wrapperBgLightGreen]: color === "ligth-green",
                    [css.wrapperBgOrange]: color === "orange",
                    [css.wrapperBgPink]: color === "pink",
                    [css.wrapperBgGold]: color === "gold",
                })}
            >
                <div className={css.backBtnWrapper}>
                    <BackButton goBack={() => setMemeScreen("memetrade")} />
                </div>

                <TradeCard
                    name={name}
                    color={color}
                    bothCount={bothCount}
                    maxCount={maxCount}
                    onClick={() => {}}
                    description={mockDescription}
                    procent={procent}
                    totalEarning={totalEarning}
                    price={price}
                    deposite={deposit}
                    size="big"
                />

                <div className={css.slotsWrapper}>
                    {bothCount === 0 && (
                        <Button className="mt-[51px]" onClick={expendBundle}>
                            {t("expand")}
                        </Button>
                    )}
                    {[...Array(bothCount)].map((_, index) => (
                        <BuyBtn
                            key={index}
                            color={color}
                            description={t("buy_slot")}
                            coast={`${price / 5}`}
                            onClick={() => {
                                makeInvest();
                            }}
                        />
                    ))}
                    {[...Array(maxCount - bothCount)].map((_, index) => (
                        <BuyBtn
                            key={index}
                            color={"disable"}
                            description={t("slot_purchased")}
                            coast={`${price / 5}`}
                            onClick={() => {
                                alert(t("slot_purchased"));
                            }}
                        />
                    ))}
                </div>
            </div>
            {isOpenModal && (
                <Modal
                    onClick={modalState.onClick}
                    type={modalState.type}
                    onClose={() => setOpenModal(false)}
                />
            )}
        </>
    );
};

export default DetailScreen;
