const missingVars = [];

if (!process.env.REACT_APP_API_URL?.trim()) missingVars.push("REACT_APP_API_URL");
if (!process.env.REACT_APP_ECVARING_API_URL?.trim()) missingVars.push("REACT_APP_ECVARING_API_URL");

if (missingVars.length > 0) {
    throw new Error(`❌ Ошибка: Не заданы переменные окружения: ${missingVars.join(", ")}. Проверь .env файл.`);
}

export const CONFIG = {
    API_URL: process.env.REACT_APP_API_URL!,
    ECVARING_API_URL: process.env.REACT_APP_ECVARING_API_URL!,
};
