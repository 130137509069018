import { FC, useEffect, useState } from "react";
import TradeCard from "../../components/TradeCard/TradeCard";
import DetailScreen from "./components/DetailScreen/DetailScreen";
import { api, apiEcvaring } from "../../api/api";
import { getCookie } from "../../helpers/cockieHelper";
import { IBundle, IUserBundle, IUserData, ModalProps } from "../interfaces";
import Modal from "../../components/UI/Modals/BuyPremiumModal/Modal";
import axios from "axios";
import { getWeeksPassed } from "../../utils/dateFormater";
import TooltipIcon from "../../components/UI/TooltipIcon";

import { postEvent } from "@telegram-apps/sdk";
import { useTranslation, UseTranslationResponse } from "react-i18next";
import { InvestBundle, mergeBundles } from "../../helpers/mergeBundle";

interface MemtradeProps {
    userData: IUserData;
    getUserData: () => Promise<void>;
}

export interface IBuyBundleBody {
    amount: string;
    bundle_id?: string;
    user_bundle_id?: number;
    slots: number;
}

const getColorByName = (name: string): string => {
    switch (name) {
        case "250":
            return "blue";
        case "500":
            return "green";
        case "1000":
            return "darck-green";
        case "1500":
            return "ligth-green";
        case "2500":
            return "orange";
        case "5000":
            return "red";
        case "10000":
            return "pink";
        case "25000":
            return "purple";
        case "50000":
            return "gold";
        default:
            return "blue";
    }
};

function findBundleIndex(bundles: IUserBundle[], bundleToFind: string): number {
    return bundles?.findIndex((bundle) => bundle?.bundle === bundleToFind);
}

function findBundleId(bundles: IUserBundle[], bundleToFind: string): number {
    const foundBundle = bundles?.find((bundle) => bundle?.bundle === bundleToFind);
    return foundBundle ? +foundBundle.id : -1;
}

function getStringBeforeDot(input: string): string {
    const dotIndex = input.indexOf(".");
    if (dotIndex === -1) {
        return input;
    }
    return input.substring(0, dotIndex);
}

const Memtrade: FC<MemtradeProps> = ({ userData, getUserData }) => {
    const { t } = useTranslation();
    const [memeScreen, setMemeScreen] = useState<string>("memetrade");
    const [cardsArr, setCardsArr] = useState<IBundle[]>([] as IBundle[]);
    const [paymentId, setPaymentId] = useState<string>();
    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [modalState, setModalState] = useState<ModalProps>({} as ModalProps);
    const [combineUserBundle, setcombineUserBundle] = useState<InvestBundle[]>(
        [] as InvestBundle[]
    );

    const [detailedData, setDetailedData] = useState({
        id: 1,
        name: "40",
        color: getColorByName("40"),
        bothCount: 1,
        maxCount: 5,
        totalEarning: 0,
        procent: "",
        price: 0,
        deposite: 0,
    });
    const [buyBundleBody, setBuyBudleBody] = useState<IBuyBundleBody>({} as IBuyBundleBody);
    const userRefLink = `${
        process.env.REACT_APP_BOT_LINK ? process.env.REACT_APP_BOT_LINK : "http://t.me/Memedao_bot"
    }/app?startapp=${userData.refery_code}`;

    const updateDetailedData = (newData: Partial<typeof detailedData>) => {
        setDetailedData((prevState) => ({
            ...prevState,
            ...newData,
        }));
    };

    const makeInvest = async (body: IBuyBundleBody) => {
        try {
            const response = await api.post(`/payments/invest_bundle/`, body, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${getCookie("key")?.toString()}`,
                    "Content-Type": "application/json",
                },
            });
    
            setPaymentId(response.data.paymentId);
            setModalState((prevState) => ({
                ...prevState,
                type: "pending",
            }));
    
            const language = localStorage.getItem("language") || "en";
    
            const paymentUrl = new URL(response.data.paymentUrl);
            paymentUrl.searchParams.set("lng", language); 
    
            const linkParams = {
                url: paymentUrl.toString(),
                try_instant_view: false,
            };
    
            postEvent("web_app_open_link", linkParams);
            setOpenModal(true);
        } catch (error) {
            console.error("Error fetching cards:", error);
        }
    };
    

    useEffect(() => {
        if (!paymentId) return;
        const intervalId = setInterval(async () => {
            try {
                const response = await apiEcvaring.get(
                    `payment/status?paymentId=${paymentId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                switch (response.data.status) {
                    // case "pending":
                    //   clearInterval(intervalId);
                    //   setModalState((prevState) => ({
                    //     ...prevState,
                    //     type: "falled",
                    //     onClick: makeInvest,
                    //   }));
                    //   break;
                    case "paid":
                        clearInterval(intervalId);
                        getUserData();
                        setModalState((prevState) => ({
                            ...prevState,
                            type: "success",
                            onClick: () => setOpenModal(false),
                        }));
                        break;
                    case "failed":
                        clearInterval(intervalId);
                        setModalState((prevState) => ({
                            ...prevState,
                            type: "falled",
                            onClick: () => setOpenModal(false),
                        }));
                        break;
                }
            } catch (error) {
                console.error("error while init invest", error);
            }
        }, 4000);
        return () => clearInterval(intervalId);
    }, [paymentId]);

    useEffect(() => {
        const getCards = async () => {
            try {
                const response = await api.get(`/invest_bundles/`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${getCookie("key")?.toString()} `,
                        "Content-Type": "application/json",
                    },
                });
                const sortedCards = [...response.data].sort(
                    (a, b) => parseFloat(a.price) - parseFloat(b.price)
                );

                setCardsArr(sortedCards);
            } catch (error) {
                console.error("Error fetching cards:", error);
            }
        };

        getCards();
        getUserData();
    }, []);

    useEffect(() => {
        setcombineUserBundle(mergeBundles(userData.invest_bundles));
    }, [userData, cardsArr]);

    const memeTradeRender = () => {
        switch (memeScreen) {
            case "memetrade":
                return (
                    <>
                        <div className="pt-[100px] max-w-xs w-full mx-auto pb-8 h-[100vh]">
                            <div className="relative">
                                <h1 className="text-custom-large tracking-custom-spaced">
                                    {t("memetrade.memetrade").toUpperCase()}
                                </h1>
                                <TooltipIcon tooltipText={t("memetrade.tooltipText")} />
                            </div>
                            <div className="flex flex-col gap-4 mt-4 pb-[100px] mb-24 ">
                                {!!cardsArr.length &&
                                    cardsArr?.map((item, index) => {
                                        const procent = item.profit_percent;
                                        const name = item.name;

                                        const price = getStringBeforeDot(item.price);

                                        const bandleIndex = combineUserBundle
                                            ? findBundleIndex(combineUserBundle, item.id)
                                            : -1;

                                        const bandleId = combineUserBundle
                                            ? findBundleId(combineUserBundle, item.id)
                                            : -1;

                                        const bothCount =
                                            bandleIndex === -1
                                                ? item.max_slots
                                                : combineUserBundle?.[bandleIndex]?.max_slots -
                                                  combineUserBundle?.[bandleIndex]?.slots;

                                        const level =
                                            Math.floor(
                                                combineUserBundle?.[bandleIndex]?.max_slots /
                                                    item.max_slots
                                            ) || 1;

                                        const slotCount = +item.price / item.max_slots;
                                        const earn =
                                            slotCount * combineUserBundle?.[bandleIndex]?.slots +
                                            slotCount * level;

                                        const cardInc =
                                            getWeeksPassed(
                                                combineUserBundle?.[bandleIndex]?.created_at
                                            ) *
                                                (+procent / 100) *
                                                earn || 0;

                                        return (
                                            <TradeCard
                                                name={name}
                                                level={level}
                                                color={getColorByName(
                                                    getStringBeforeDot(item.price)
                                                )}
                                                bothCount={bothCount}
                                                maxCount={item.max_slots}
                                                procent={procent}
                                                totalEarning={cardInc}
                                                price={+price}
                                                deposite={earn}
                                                userRef={userRefLink}
                                                onClick={() => {
                                                    if (bandleId === -1) {
                                                        setOpenModal(true);

                                                        setModalState((prevState) => ({
                                                            ...prevState,
                                                            type: "premium",
                                                            onClick: () =>
                                                                makeInvest({
                                                                    amount: `${+price / 5}.00`,
                                                                    bundle_id: item.id,
                                                                    slots: 0,
                                                                }),
                                                            amount: `${+price / 5}$`,
                                                        }));
                                                    } else {
                                                        updateDetailedData({
                                                            name: name,
                                                            color: getColorByName(
                                                                getStringBeforeDot(price)
                                                            ),
                                                            bothCount: bothCount,
                                                            id: index,
                                                            maxCount: Number(item.max_slots),
                                                            totalEarning: cardInc,
                                                            procent: procent,
                                                            deposite: earn,
                                                            price: +price,
                                                        });
                                                        if (bandleId === -1 || bothCount === 0) {
                                                            setBuyBudleBody({
                                                                amount: `${+price / 5}.00`,
                                                                bundle_id: item.id,
                                                                slots: 1,
                                                            });
                                                        } else {
                                                            setBuyBudleBody({
                                                                amount: `${+price / 5}.00`,
                                                                user_bundle_id: bandleId,
                                                                slots: 1,
                                                            });
                                                        }
                                                        setMemeScreen("detailed");
                                                    }
                                                }}
                                                size="smal"
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                        {isOpenModal && (
                            <Modal
                                onClick={modalState.onClick}
                                type={modalState.type}
                                amount={modalState?.amount}
                                onClose={() => setOpenModal(false)}
                            />
                        )}
                    </>
                );
            case "detailed":
                return (
                    <DetailScreen
                        id={detailedData.id}
                        name={detailedData.name}
                        color={detailedData.color}
                        bothCount={detailedData.bothCount}
                        maxCount={detailedData.maxCount}
                        setMemeScreen={setMemeScreen}
                        buyBundleBody={buyBundleBody}
                        getUserData={getUserData}
                        procent={detailedData.procent}
                        totalEarning={detailedData.totalEarning}
                        deposit={detailedData.deposite}
                        price={+detailedData.price}
                    />
                );
        }
    };
    return <>{memeTradeRender()}</>;
};

export default Memtrade;
